import { render, staticRenderFns } from "./ReturnPaymentPage.vue?vue&type=template&id=1efb92ae&scoped=true"
import script from "./ReturnPaymentPage.vue?vue&type=script&lang=js"
export * from "./ReturnPaymentPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1efb92ae",
  null
  
)

export default component.exports