<template>
    <div class="apaympro-container">

        <!-- Top Bar Component --> 
        <top-bar></top-bar> 
        <!-- Top Bar Component -->

        <!-- Loader -->
        <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            loader="bars"
            :opacity="1"
        ></loading>

        <!-- Form Payment -->
        <div class="apaympro-wrapper animated fadeIn container" v-if="formPayment">
            <div class="apaympro-page">
                <div class="apaympro-header">
                    <div 
                        class="company-logo mt-4" 
                        v-if="dataPage.business_logo"
                        style="width: 150px !important; justify-content: center;"
                    >
                        <img
                            :src="dataPage.business_logo"
                            alt="Business Logo"
                        />
                    </div>

                    <div class="page-info">
                        <h3 id="page-name" class="page-name">
                            {{ dataPage.type == 'ONG' || dataPage.type == 'PERSONNALITE' ? $t('text_header.donation.value') : $t('text_header.pay.value') }} <strong style="text-transform: uppercase;"> {{ dataPage.business_name }} <img src="../assets/img/badge-check.png" v-if="dataPage.verified == '1'" width="15" style="margin-top: -3px;" /> </strong>
                        </h3>
                        <p class="company-name text-dark" v-if="dataPage.type == 'SP'">
                            {{$t('text_header.by.value')}} <span style="font-weight: 600;">{{ dataPage.nom }}</span> <br>
                            <span class="font-12" style="text-transform: none;">{{$t('text_header.enterprising.value')}}</span>
                        </p>
                        <p class="company-name" v-if="dataPage.type !== 'SP' && dataPage.type !== 'AUTRE'">
                            <span style="font-weight: 600;">{{ dataPage.type }} {{ dataPage.rcc !== "0" || dataPage.rcc.includes("---") == "-1" ? '- ' + dataPage.rcc : '' }}</span>
                        </p>
                        <p class="company-name mb-2" style="font-weight: 500; font-size: 11px">{{ dataPage.ville }}</p>
                    </div>

                    <div class="page-description" v-if="dataPage.description_page">
                        <span id="tooltip-description-page" style="cursor: pointer;">
                            {{ 
                                dataPage.description_page
                            }}
                        </span>
                        <b-tooltip target="tooltip-description-page" triggers="hover">
                            {{ dataPage.description_page_2 }}
                        </b-tooltip>
                    </div>

                    <!-- Social medias -->
                    <div class="company-name mt-3" style="text-transform: none;">
                        <span v-if="dataPage.facebook">
                            <a class="btn btn-lg btn-link btn-facebook" :href="dataPage.facebook" target="_blank" style="padding: 0;">
                                <i class="fab fa-facebook-f"></i>
                            </a> 
                        </span> 
                        <span v-if="dataPage.instagram" class="mr-4 ml-4">
                            <a class="btn btn-lg btn-link btn-instagram" :href="dataPage.instagram" target="_blank" style="padding: 0;">
                                <i class="fab fa-instagram"></i>
                            </a> 
                        </span> 
                        <span v-if="dataPage.whatsapp">
                            <a class="btn btn-lg btn-link btn-whatsapp" :href="dataPage.whatsapp" target="_blank" style="padding: 0;">
                                <i class="fab fa-whatsapp"></i>
                            </a> 
                        </span> 
                    </div>
                    <!-- Social medias -->  

                </div>

                <div class="body">
                    <!-- Bloc success payment -->
                    <div :class="hideRecu == true ? 'payment-success animated fadeIn text-center hidden' : 'payment-success animated fadeIn text-center'">
                        <img src="../assets/confirmico.gif" alt="Logo payment success"/>
                        <div>
                            <strong>
                                {{ $t('payment_successfully.success.value') }}
                            </strong>
                            <br>
                            <span> 
                                {{ $t('payment_successfully.by.value') }} {{ dataFormulaire.nom }} {{ dataFormulaire.prenom }}
                            </span>
                            <h2 class="payment-success-message" style="font-weight: 900; font-size: 3rem;">
                                <strong>
                                    {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format( dataFormulaire.montant ) }}
                                </strong>
                            </h2>
                            <div v-if="dataFormulaire.motif" class="mb-3">
                                {{ $t('payment_successfully.reason.value') }} : {{ dataFormulaire.motif }}
                            </div>

                            <span class="" 
                                style="
                                    align-items: center;
                                    justify-content: center;
                                "
                            >
                                {{ $t('payment_successfully.by.value') }}  
                                <img 
                                    :src="require(`../assets/img/menu/${logoSmallDevice}`)" 
                                    width="70" alt="Logo" style="position: relative; left: 5px;" 
                                />
                            </span>
                        </div>
                    </div>

                    <div class="element-wrap element-wrap-for-submit mt-5 mb-5">  
                        <a
                            :href="'https://pay.apaym.com/recu/'+ refPayment" target="_blank"
                            :class="hideRecu == true ? 'btn btn-dark hidden btn-block' : 'btn btn-dark btn-block font-15'"
                        >{{ $t('payment_successfully.receipt.value') }}</a>

                        <a
                            href="https://apaym.com/app" target="_blank"
                            :class="hideRecu == true ? 'btn btn-dark hidden btn-block' : 'btn btn-outline-dark btn-block font-15'"
                        >{{ $t('download_apaym.value') }}</a>

                        <button @click="reload" :class="hideRecu == true ? 'btn btn-link hidden btn-block' : 'btn btn-link btn-block'" style="font-weight: 800; color: #0e2abd;">
                            {{ $t('payment_successfully.button.value') }}
                        </button>

                    </div>
                </div>
            </div>
        </div>
        <!-- Form Payment -->

        <!-- Error Section -->
        <div class="apaympro-wrapper animated fadeIn" v-if="showErrorPage">

            <div class="apaympro-header">

                <div class="page-info text-center pt-3">
                    <h3 class="page-name">
                        {{ $t('transaction_paid.text.value') }}
                    </h3>
                </div>

            </div>

            <div class="apaympro-page">
                
                <div class="body">
                    
                    <!-- Bloc error payment -->
                    <div class="payment-success animated fadeIn text-center">
                        <img src="../assets/refusico.gif" alt="Logo payment error"/>
                        <div>
                            <h3 style="font-weight: 500; font-size: 1.38rem;">
                                {{ $t('transaction_paid.text2.value') }}
                            </h3>
                            <p style="font-size: 1.28rem; font-weight: 800" class="mt-5">
                                <strong>{{ errorMessage }}</strong>
                            </p>
                        </div>
                    </div>
                    <div class="element-wrap element-wrap-for-submit mt-5 mb-5">
                        <a
                            type="button"
                            id="pay-btn"
                            href="https://pay.apaym.com"
                            target="_blank"
                            class="btn btn-outline-danger btn-block"
                        >{{ $t('transaction_paid.apaym.value') }}</a>
                    </div>
                </div>
            </div>
            
            <!-- <div class="apaympro-page">
                <div class="apaympro-header">
                    <div class="page-info mt-3 mb-3">
                        <h3 style=" font-size: 20px; font-weight: normal; margin: 20px auto 0px;  width: 80%; line-height: 1.3; " >
                            {{ errorMessage }} {{ $t('transaction_paid.text.value') }}
                        </h3>
                    </div>
                    <div class="mt-3">
                        <img
                            src="../assets/refusico.gif"
                            alt="icon page not found - payment page apaym pro"
                            width="200"
                            height="200"
                            style="width: 200px; height: 100%"
                        />

                    </div>
                    <div class="element-wrap element-wrap-for-submit mt-5 mb-5">
                        <a type="button" id="pay-btn" href="https://pay.apaym.com" target="_blank" class="btn btn-outline-danger btn-block">
                            {{ $t('transaction_paid.apaym.value') }}
                        </a>
                    </div>
                </div>
            </div> -->

        </div>
        <!-- Error Section -->

        <!-- Footer APaym Secured Badge -->
        <footer-item></footer-item>
        <!-- Footer APaym Secured Badge -->

    </div>
</template>


<script src="https://js.pusher.com/7.0/pusher.min.js"></script>
<script>

    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import axios from 'axios';
    import { apiUrl, _reference } from '../constants/config';
    import { trim } from 'jquery';
    import Multiselect from "vue-multiselect";
    import TopBar from '../components/TopBar' ;
    import FooterItem from '../components/FooterItem';

    export default {
        components: {
            Loading,
            multiselect: Multiselect,
            'top-bar': TopBar,
            'footer-item': FooterItem,
        },
        data() {
            return {
                showCard: false, cards: [], cardsLength: 0, loginApaym: true,
                hideCodePay: false, tabIndex: 0, mtnOkay: false, mtnNOkay: false,
                orangeOkay: false, orangeNOkay: false, moovOkay: false, moovNOkay: false,
                waveOkay: false, waveNOkay: false, isLoadingWave: false, fullPageWave: false,
                biciciNOkay: false, hideRecu: true, isLoading: false, fullPage: true,
                isLoadingQr: false, fullPageQr: false, isLoadingMomo: false, fullPageMomo: false,
                isLoadingAPaym: false, fullPageAPaym: false, isLoadingAPaymAuth: false, fullPageAPaymAuth: false,
                isLoadingOrange: false, fullPageOrange: false, isLoadingFlooz: false, fullPageFlooz: false,
                isLoadingBicici: false, fullPageBicici: false, indice: "225", indiceMobileMoney: "225",
                json: [], qrCodeUri: "", stopInterval: null, urlOrange: "", isBicici: false, isOtherCard: false,
                cardChoice: true, contentBicici: "", formBicici: false, cardType: "",
                dataFormulaire: {
                    nom: "",
                    prenom: "",
                    telephone: "",
                    email: "",
                    montant: "",
                    motif: "",
                    devise: "XOF",
                    society: ""
                }, 
                url_data: {
                    code_page: "",
                    slug: ""
                },
                dataPage: {
                    name_page: "",
                    description_page: "",
                    description_page_2: "",
                    business_logo: "",
                    business_name: "",
                    business_vpan: "",
                    amount: "",
                    page_redirect: "",
                    email_notify: "",
                    success_message: "",
                    seo_image: "",
                    slug: "",
                    type: "",
                    nom: "",
                    verified: "",
                    rcc: "",
                    business_email: "",
                    business_phone: "",
                    facebook: "",
                    instagram: "",
                    whatsapp: "",
                    ville: "",
                    isDeleg: "",
                    urlRedirection: ""
                },
                options: [], pageType: "default",
                company: {
                    name: "",
                    address: "",
                },
                invoice: {
                    id: "",
                    items: [],
                    dueDate: ""
                },
                customer: {
                    name: "",
                    email: "",
                    description: "",
                },
                logo: "", logoOfSelectedService: "visa-qr-1.png", logoSmallDevice: "wave.png",
                showErrorPage: false, formQuiclyPayment: true, infosSup: true,
                merchantList: [], searchMerchant: "", typeForm: "", modePay: "", refPayment: "",
                langs: ['fr', 'en'], modalOrange: false, modalBicici: false, mobileDevice: false,
                modePayment: "", showMenu: true, showHeader: false, showContent: false, menuSelected: "Visa QR",
                dataYangoRechargement: { 
                    driver_profil_id : "",
                    driver_phone_number: "",
                    amount: "",
                    profil_id: "",
                    ref_transaction: "",
                    adresse_ip: "",
                    device_ID: "",
                    phone_Name: ""
                },
                pointLoyalty: [],
                loyalty_card: "",
                nombre_points: 0,
                cadeau_a_offrir: "",
                nombre_paiements: 0,
                nombre_transactions: 0,
                statusClient: "",  
                imgStatus: "bronze.png",
                is_platinum_card: "",
                showPaymentSection: false,
                formPayment: false,
                modalWave: false,
                urlWave: "",
                errorMessage: ""
            }
        },
        computed: {
        },
        methods: {

            onCancel() {
                console.log('Loader dismiss') ;
            },

            reload(){
                window.location.href = "https://pay.apaym.com/" ;
            },

            // Get information of payment page
            checkReference(ref) {

                this.isLoading = true;
                let dataSend = new FormData();
                dataSend.append("reference", ref+"#");

                console.log(ref)
                // return false;

                axios.post(apiUrl + 'api/paiement-marchand/statut-paiement',
                    dataSend
                ).then(response => {

                    console.log(response)
                    // return false;

                    this.isLoading = false;

                    if (response.data.code !== 1) {
                        this.showErrorPage = true ;
                        return false;
                    }

                    if (response.data.reference !== ref +"#") {
                        this.showErrorPage = true ;
                        return false;
                    }

                    this.formPayment = true ;
                    this.dataPage.business_name = response.data.business ;
                    this.dataFormulaire.montant = Math.round(parseFloat(response.data.amount)) ;
                    this.dataFormulaire.devise = response.data.currency ;
                    this.dataFormulaire.nom = response.data.consumer_name ;
                    this.dataPage.type = response.data.type ;
                    this.dataFormulaire.motif = trim(response.data.motif) ;
                    this.dataPage.description_page_2 = response.data.description_page;
                    this.dataPage.description_page = response.data.description_page ? this.getDescriptionTronque(response.data.description_page, 90) : response.data.description_page ;
                    this.dataPage.business_logo = response.data.business_logo !== "" ? "https://apaym.com/cms/images/logo_marchand/"+ response.data.business_logo : "" ;
                    this.dataPage.verified = response.data.verified;   
                    this.dataPage.rcc = response.data.rcc;
                    this.dataPage.business_email = response.data.MerchantEmail; 
                    this.dataPage.business_phone = response.data.MerchantPhone; 
                    this.dataPage.facebook = response.data.facebook; 
                    this.dataPage.instagram = response.data.instagram; 
                    this.dataPage.whatsapp = response.data.whatsapp; 
                    this.dataPage.nom = response.data.nom;
                    this.dataPage.ville = response.data.ville; 
                    this.dataPage.isDeleg = response.data.isDeleg;
                    this.dataPage.urlRedirection = response.data.urlRedirection;
                    
                    this.selectMenu(response.data.mode_pay) ;
                    this.hideRecu = false ;
                    this.refPayment = response.data.reference ;

                    this.dataPage.urlRedirection !== "" && this.dataPage.urlRedirection !== null ? setTimeout( window.location.href = this.dataPage.urlRedirection, 12000) : "" ;

                }).catch(error => {

                    console.log(error);
                    this.isLoading = false;
                    this.$swal(
                        'Un erreur est survenue !',
                        error.response.data,
                        'error'
                    );

                });
            },

            getDescriptionTronque(text, nombreCharAtronquer) {
                let 
                    delim = ' [suite...]',
                    length = nombreCharAtronquer
                ;

                if( nombreCharAtronquer < text.length ) {

                    while ( (text[length] != " ") && (length > 0) ) {
                        length--;
                    }

                    if (length == 0) {
                        return text.substring(0, nombreCharAtronquer) + delim;
                    }
                    
                    return text.substring(0, length) + delim;
                } 
                
                return text;
            },

            selectMenu(payment){
                
                this.menuSelected = payment ;
                this.showMenu = !this.showMenu ;
                this.showHeader = !this.showHeader ;
                this.showContent = !this.showContent ;

                // Literal Object JavaScript
                const typePayment = {
                    'QR' : "visa-qr.png",
                    'VISA' : "visa.png",
                    'UBA' : "visa.png",
                    'Orabank - VISA' : "visa.png",
                    'Orabank - MASTERCARD' : "mastercard.png",
                    'MASTERCARD' : "mastercard.png",
                    'Orange Money' : "om.png",
                    'Wave' : "wave.png",
                    'MTN Money' : "momo.png",
                    'Moov Money' : "flooz.png",
                    'APaym USSD' : "apaym-ussd.png",
                    'APaym' : "apaym.png",
                }

                this.logoSmallDevice = typePayment[payment] ;
                this.cardType = payment == 'VISA' || payment == 'MASTERCARD' ? payment : '' ;
            },
            
        },
        mounted() {
            
            this.url_data.reference = this.$route.params.reference;
            if (!this.url_data.reference) {
                this.showErrorPage = true ;
                return false;
            }
            console.log(this.$route.params.message)
            this.errorMessage = this.$route.params.message ? this.$route.params.message : this.$t('payment_modal.om.payment_failure_details.value');
            // console.log(this.errorMessage)
            this.checkReference(this.url_data.reference);
        },
        watch: {
        }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>